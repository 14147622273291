import React, { useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

const loggedOut = 'https://kalender.digital/e7e6f9bb96beea2cb5a5?iframe=true'
const loginUrl = (PROD) =>
  PROD
    ? '/.netlify/functions/hello'
    : 'https://tcfrankenberg.netlify.app/.netlify/functions/hello'

const Iframe = (src) =>
  '<iframe frameborder="0" height="1000px" width="100%" src="' +
  src +
  '"></iframe>'

export const TerminPageTemplate = ({ title }) => {
  const calRef = useRef(null)
  const [pwd, setPwd] = useState()
  const [admin, setAdmin] = useState()

  const fetchCal = () =>
    fetch(loginUrl(true) + '?pwd=' + pwd)
      .then((response) => response.json())
      .then((json) => {
        if (json && json.iframe) {
          setAdmin(true)
          sessionStorage.setItem('src', json.iframe)
          calRef.current.innerHTML = Iframe(json.iframe)
        }
      })
      .catch((e) => {
        console.log(e)
      })

  const onChange = (e) => {
    setPwd(e.target.value)
  }

  const onClick = () => {
    fetchCal()
  }

  useEffect(() => {
    const loggedIn = sessionStorage.getItem('src')
    setAdmin(!!loggedIn)
    calRef.current.innerHTML = Iframe(loggedIn || loggedOut)
  }, [])

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              {!admin && (
                <form className="login" onSubmit={(e) => e.preventDefault()}>
                  <input
                    className="input"
                    placeholder="Passwort"
                    type="password"
                    name="password"
                    onChange={onChange}
                  />
                  <button className="btn" onClick={onClick}>
                    Anmelden zum Termin anlegen
                  </button>
                </form>
              )}
              <div className="calendar" ref={calRef}></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const TerminPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const {
    title,
    calendarTitle,
    mailto,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
  } = frontmatter

  return (
    <Layout>
      <TerminPageTemplate
        title={title}
        calendarTitle={calendarTitle}
        mailto={mailto}
        monday={monday}
        tuesday={tuesday}
        wednesday={wednesday}
        thursday={thursday}
        friday={friday}
        saturday={saturday}
        sunday={sunday}
      />
    </Layout>
  )
}

export default TerminPage

export const terminPageQuery = graphql`
  query TerminPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
